<template lang="pug">
div.main
  div.fs-44.fw-700.font-head-light.mt-6 {{ $t('seamansDocumentVerificationService') }}
  div.wrapper
    VerificationForm.mr-6
    DocumentPreview
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Main',
  components: {
    VerificationForm: () => import('@/components/layouts/VerificationForm'),
    DocumentPreview: () => import('@/components/layouts/DocumentPreview')
  },
  computed: {
    ...mapState({
      isLoad: state => state.app.isLoad,
      type:  state => state.app.typeDocument
    })
  }
}
</script>
